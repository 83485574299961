define("discourse/plugins/discourse-mark/initializers/mark", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeMark(api) {
    api.addToolbarPopupMenuOptionsCallback(() => {
      return {
        action: "insertMark",
        icon: "highlighter",
        label: "mark.title"
      };
    });
    api.modifyClass("controller:composer", {
      actions: {
        insertMark() {
          this.get("toolbarEvent").applySurround("==", "==", "mark_text", {
            multiline: true
          });
        }
      }
    });
  }
  var _default = _exports.default = {
    name: "apply-mark",
    initialize(container) {
      if (!container.lookup("site-settings:main").mark_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.8.23", initializeMark);
    }
  };
});