define("discourse/plugins/discourse-mark/lib/discourse-markdown/mark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;
  function setup(helper) {
    if (!helper.markdownIt) {
      return;
    }
    helper.registerOptions((opts, siteSettings) => {
      opts.features["mark"] = !!siteSettings.mark_enabled;
    });
    helper.whiteList(["mark"]);
    helper.registerPlugin(window.markdownitMark);
  }
});